import React from "react"

const EyeIcon = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4885 12.9485C9.50979 14.3721 7.89589 16.1163 6.87556 17.3551C6.55711 17.7417 6.55711 18.2583 6.87556 18.6449C7.89589 19.8837 9.50979 21.6279 11.4885 23.0515C13.479 24.4836 15.7111 25.5 17.9998 25.5C20.2885 25.5 22.5205 24.4836 24.5111 23.0515C26.4898 21.6279 28.1037 19.8837 29.124 18.6449C29.4424 18.2583 29.4424 17.7417 29.124 17.3551C28.1037 16.1163 26.4898 14.3721 24.5111 12.9485C22.5205 11.5164 20.2885 10.5 17.9998 10.5C15.7111 10.5 13.479 11.5164 11.4885 12.9485ZM9.73643 10.5133C11.991 8.89121 14.8349 7.5 17.9998 7.5C21.1647 7.5 24.0086 8.89121 26.2631 10.5133C28.5295 12.1439 30.3299 14.1004 31.4397 15.4478C32.6706 16.9422 32.6706 19.0578 31.4397 20.5522C30.3299 21.8996 28.5295 23.8562 26.2631 25.4867C24.0086 27.1088 21.1647 28.5 17.9998 28.5C14.8349 28.5 11.991 27.1088 9.73643 25.4867C7.47002 23.8561 5.6697 21.8996 4.5599 20.5522C3.32899 19.0578 3.32899 16.9422 4.5599 15.4478C5.6697 14.1004 7.47003 12.1438 9.73643 10.5133Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15ZM12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18Z"
        fill="#004F57"
      />
    </svg>
  );
}

export default EyeIcon