import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// components
import HomeMobileIconContainer from "../../shared/HomeMobileIconContainer";

// icons
import LargeAudienceIcon from "../../Icons/LargeAudienceIcon";
import DiamondIcon from "../../Icons/DiamondIcon";
import ChatsIcon from "../../Icons/ChatsIcon";
import SecureIcon from "../../Icons/SecureIcon";
import ReliableIcon from "../../Icons/ReliableIcon";
import WorldWideIcon from "../../Icons/WorldWideIcon";
import BullhornIcon from "../../Icons/BullhornIcon";
import EyeIcon from "../../Icons/EyeIcon";
import UserPlus from "../../Icons/UserPlus";
import ShowMore from "../../shared/ShowMore";

const HOME_PAGE_URL = "/profile/home";

const JoinUsSection = ({ isAuthenticated }) => {
  return (
    <section className="home--join" data-testid="home-join-section">
      <div className="page-container">
        <p className="home--join-title">
          Join
          <br />
          <span className="highlight">Love</span>Antiques
          <span className="highlight">.com</span>
        </p>

        <p className="home--info">
          LoveAntiques.com is perfect for both collectors and antique dealers in
          the UK to buy and sell a wide assortment of different rare antiques.
          There are many benefits from using our website for both collectors and
          dealers:
        </p>

        <Row gutter={[30, 30]}>
          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As a <span className="highlight">Collector</span> - Why join and
              how to buy antiques online on LoveAntiques.com?
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <ShowMore lines={6} className="read-more-text">
                  One of the most significant benefits of buying from
                  LoveAntiques.com for a collector is that we have one of the
                  largest selections of antiques for sale, UK wide, whether you
                  are looking for antiques in London, or anywhere else
                  throughout the country and even around the world. You can
                  contact the dealers directly and ask any questions that you
                  may have about their products, as well as pay for your
                  purchases securely using either card payments, or PayPal. With
                  every antique dealer being scrutinised before they join
                  LoveAntiques.com, you can also rest assured that you are
                  dealing with reputable, and reliable antique dealers.
                </ShowMore>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated
                        ? "/registration/collector"
                        : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Collector
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<DiamondIcon />}
                  iconTitle="Largest Antique Collection"
                />
                <HomeMobileIconContainer
                  iconNode={<ChatsIcon />}
                  iconTitle="Easy to contact dealer"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<ReliableIcon />}
                  iconTitle="Reliable antique dealers"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={
                    !isAuthenticated ? "/registration/collector" : HOME_PAGE_URL
                  }
                >
                  <Button className="home--join-button" size="large">
                    Join as Collector
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As a <span className="highlight">Dealer</span> - Why join and how
              to list your antiques for sale online in UK
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <ShowMore lines={6} className="read-more-text">
                  If you are a dealer and have items that are vintage, antique,
                  or collectable for sale, LoveAntiques.com is the perfect
                  platform for you to sell your products on a worldwide scale,
                  helping you to grow your business. Once you have created your
                  account and it has been approved, you upload your items for
                  sale providing detailed descriptions. We will then optimise
                  the pages your products appear on for SEO purposes, and your
                  stock will instantly be in front of thousands of people.
                  Potential buyers can contact you directly if they have any
                  questions, and all payments go directly to you. If you are
                  looking to grow your business, LoveAntiques.com is the
                  solution that can help drive sales by getting your products in
                  front of a much larger audience.
                </ShowMore>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Dealer
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<WorldWideIcon />}
                  iconTitle="Worldwide scale"
                />
                <HomeMobileIconContainer
                  iconNode={<ChatsIcon />}
                  iconTitle="Easy to contact buyers"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<LargeAudienceIcon />}
                  iconTitle="Large audience"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={
                    !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                  }
                >
                  <Button className="home--join-button" size="large">
                    Join as Dealer
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As an <span className="highlight">Antiques Business</span> - Why
              register and how to promote your business on LoveAntiques.com?
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <ShowMore lines={6} className="read-more-text">
                  Registering your antiques business with LoveAntiques.com
                  allows you to reach a broader audience and gain visibility
                  among thousands of potential customers. Whether you choose the
                  free or premium listing option, your business details will be
                  prominently displayed in our directory, making it easier for
                  antique enthusiasts to find and contact you. Our platform
                  provides an excellent opportunity to showcase your unique
                  offerings, from antique furniture to vintage collectibles, and
                  connect with a community of passionate buyers and sellers. By
                  joining LoveAntiques.com, you can effectively promote your
                  business, drive traffic to your website, and increase your
                  sales.
                </ShowMore>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/trader" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Register your Business
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<BullhornIcon />}
                  iconTitle="Promote your business"
                />
                <HomeMobileIconContainer
                  iconNode={<EyeIcon />}
                  iconTitle="Increase visibility"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<UserPlus />}
                  iconTitle="Connect with customers"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={!isAuthenticated ? "/registration/trader" : HOME_PAGE_URL}
                >
                  <Button className="home--join-button" size="large">
                    Register your Business
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <p className="h2 home--join-info">More Information?</p>

            <div style={{ marginBottom: "20px" }}>
              <ShowMore lines={6} className="read-more-text">
                If you want to know more about how the site works, or how to
                join as a collector or a dealer, send us an email to
                info@loveantiques.com, or use our online contact form. If you
                love to buy or sell antiques, then LoveAntiques.com is the place
                for you!
              </ShowMore>

              <div className="flex home--join-button-container">
                <Link to="/contact-us">
                  <Button className="home--join-button" size="large">
                    Ask a Question
                  </Button>
                </Link>

                <Link to="/faq">
                  <Button
                    className="home--join-button home--join-button_right"
                    size="large"
                    ghost
                  >
                    View FAQ
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

JoinUsSection.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
}))(JoinUsSection);
