import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import { Input, Button } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// helpers
import { MESSAGE_REQUIRED_FIELD } from "../../constants";

// assets
import "../../assets/styles/shared/subscribeNewsletter.scss";


const validation = yup.object().shape({
  name: yup.string().required(MESSAGE_REQUIRED_FIELD),
  email: yup.string().required(MESSAGE_REQUIRED_FIELD)
});


function SubscribeNewsletter({ isAuthenticated, "data-testid": dataTestId }) {
  const history = useHistory();

  const { handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validation),
  });

  const { name, email } = watch();

  const onSubmit = (data) => {
    history.push({
      pathname: "/registration/collector",
      state: data,
    })
  };

  if (isAuthenticated) {
    return <div />;
  }

  /* TODO add newsletter icon */
  return (
    <div className="home-subscribe" data-testid={dataTestId}>
      <div className="flex">
        <img
          className="home-subscribe--icon"
          src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/Mail%403x.svg`}
          alt="subscribe"
          title="subscribe"
          width={44}
          height={42}
        />

        <p className="home-subscribe--text">
          Get alerts of the antiques that interest you as soon as they are uploaded
        </p>
      </div>

      <form className="home-subscribe--form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="home-subscribe--input"
          placeholder="Name"
          value={name}
          name="name"
          onChange={e => setValue("name", e.target.value)}
        />

        {errors && errors.name && (
          <span className="home-subscribe--input-error">
            {errors.name.message}
          </span>
        )}

        <Input
          className="home-subscribe--input"
          placeholder="Email"
          value={email}
          name="email"
          onChange={e => setValue("email", e.target.value)}
        />

        {errors && errors.email && (
          <span className="home-subscribe--input-error">
            {errors.email.message}
          </span>
        )}

        <Button className="home-subscribe--button" htmlType="submit" size="large">Subscribe</Button>
      </form>
    </div>
  )
}

SubscribeNewsletter.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  "data-testid": PropTypes.string
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps, null)(SubscribeNewsletter);
