import React from "react";

function WorldWideIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 6.1875C11.4761 6.1875 6.1875 11.4761 6.1875 18C6.1875 24.5239 11.4761 29.8125 18 29.8125C24.5239 29.8125 29.8125 24.5239 29.8125 18C29.8125 11.4761 24.5239 6.1875 18 6.1875ZM2.8125 18C2.8125 9.61217 9.61217 2.8125 18 2.8125C26.3878 2.8125 33.1875 9.61217 33.1875 18C33.1875 26.3878 26.3878 33.1875 18 33.1875C9.61217 33.1875 2.8125 26.3878 2.8125 18Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8125 18C2.8125 17.068 3.56802 16.3125 4.5 16.3125H31.5C32.432 16.3125 33.1875 17.068 33.1875 18C33.1875 18.932 32.432 19.6875 31.5 19.6875H4.5C3.56802 19.6875 2.8125 18.932 2.8125 18Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5738 9.37691C14.6655 11.4978 14.0625 14.5453 14.0625 18C14.0625 21.4546 14.6655 24.5022 15.5738 26.623C16.0292 27.6865 16.5282 28.4392 16.9963 28.9015C17.4572 29.3567 17.7944 29.4469 18 29.4469C18.2056 29.4469 18.5428 29.3567 19.0037 28.9015C19.4718 28.4392 19.9708 27.6865 20.4262 26.623C21.3345 24.5022 21.9375 21.4546 21.9375 18C21.9375 14.5453 21.3345 11.4978 20.4262 9.37691C19.9708 8.31346 19.4718 7.56073 19.0037 7.09842C18.5428 6.64323 18.2056 6.5531 18 6.5531C17.7944 6.5531 17.4572 6.64323 16.9963 7.09842C16.5282 7.56073 16.0292 8.31346 15.5738 9.37691ZM14.6247 4.69711C15.5097 3.82303 16.6523 3.1781 18 3.1781C19.3477 3.1781 20.4903 3.82303 21.3753 4.69711C22.2531 5.56407 22.9662 6.73482 23.5287 8.04823C24.6563 10.6811 25.3125 14.2007 25.3125 18C25.3125 21.7992 24.6563 25.3189 23.5287 27.9517C22.9662 29.2651 22.2531 30.4359 21.3753 31.3028C20.4903 32.1769 19.3477 32.8219 18 32.8219C16.6523 32.8219 15.5097 32.1769 14.6247 31.3028C13.7469 30.4359 13.0338 29.2651 12.4713 27.9517C11.3437 25.3189 10.6875 21.7992 10.6875 18C10.6875 14.2007 11.3437 10.6811 12.4713 8.04823C13.0338 6.73482 13.7469 5.56407 14.6247 4.69711Z"
        fill="#004F57"
      />
    </svg>
  )
}

export default WorldWideIcon;
