import React from "react";
import PropTypes from "prop-types";
import { Col, Skeleton } from "antd";

import "../../assets/styles/shared/itemBlock.scss";


function ItemBlockSkeleton({ amount = 0 }) {
  const items = [];

  for (let i = 0; i < amount; i++) {
    items.push(
      <Col key={`item-skeleton${i}`} lg={6} sm={8} xs={12}>
        <div className="home-item skeleton" data-testid="lant-item-skeleton">
          <div className="home-item--image-holder" />
          <Skeleton
            title={{ width: "70%" }}
            paragraph={{ rows: 5, width: ["100%", "100%", "50%", "30%", "80%"] }}
          />
        </div>
      </Col>
    );
  }

  return items;
}

ItemBlockSkeleton.propTypes = {
  amount: PropTypes.number
};

export default React.memo(ItemBlockSkeleton);
