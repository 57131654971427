import React from "react";
import PropTypes from "prop-types";

//styles
import "../../assets/styles/shared/home-mobile-icon.scss";

const HomeMobileIconContainer = ({ iconNode, iconTitle }) => {
  return (
    <div className="icon-container">
      <p className="icon-container--icon">
        {iconNode}
      </p>
      <p className="icon-container--title">
        {iconTitle}
      </p>
    </div>
  )
}

HomeMobileIconContainer.propTypes = {
  iconNode: PropTypes.node.isRequired,
  iconTitle: PropTypes.string.isRequired
}

export default HomeMobileIconContainer;
