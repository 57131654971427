import React from "react"

// components
import MostPopularCategory from "./MostPopularCategory";

// constants
import { MAIN_CATEGORIES } from "../../../constants/categories";

const CategoriesSection = () => {
  return (
    <section className="home--recommended">
      <div className="home--recommended-container page-container">
        <h1 className="home--recommended-container--heading">
          Buy genuine antiques online from trusted UK antique dealers
        </h1>

        <p className="home--recommended-container--subheading">
          Browse <span className="color-red">30,000+</span> antiques from over{" "}
          <span className="color-red">400</span> verified dealers
        </p>

        <div
          className="home--category-block-container"
          data-testid="home-category-block-container"
        >
          {MAIN_CATEGORIES.map((category, index) => (
            <MostPopularCategory key={index} {...category} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default CategoriesSection