import React from "react";

function BullhornIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.3126 16.875C34.3103 14.9363 33.5392 13.0776 32.1683 11.7068C30.7974 10.3359 28.9388 9.56474 27.0001 9.56251H21.4201C20.9363 9.53298 14.0232 9.00001 7.43349 3.47345C7.02358 3.12929 6.52397 2.90925 5.99334 2.83916C5.46272 2.76907 4.92311 2.85185 4.4379 3.07777C3.95268 3.30369 3.54201 3.66338 3.2541 4.11458C2.9662 4.56579 2.81302 5.08978 2.81256 5.62501V28.125C2.80917 28.6609 2.96049 29.1863 3.24834 29.6383C3.53619 30.0903 3.94836 30.4496 4.43537 30.6731C4.9203 30.9021 5.46089 30.9865 5.99254 30.9165C6.5242 30.8464 7.02443 30.6247 7.43349 30.278C10.8064 27.4425 14.8193 25.4713 19.1251 24.5349V28.2192C19.125 28.6821 19.2392 29.1379 19.4575 29.5461C19.6758 29.9543 19.9915 30.3023 20.3766 30.5592C20.439 30.6013 20.5044 30.6389 20.5721 30.6717L22.6027 31.6645C22.978 31.8892 23.4006 32.0233 23.8368 32.056C24.273 32.0887 24.7109 32.0193 25.1155 31.8531C25.5202 31.687 25.8805 31.4287 26.1679 31.0989C26.4552 30.7691 26.6617 30.3767 26.7708 29.9531L28.3304 24.0722C30.0108 23.7594 31.5288 22.8685 32.6213 21.554C33.7139 20.2396 34.3122 18.5843 34.3126 16.875ZM6.18756 26.948V6.80626C11.274 10.7536 16.3365 12.1641 19.1251 12.6647V21.0938C16.3365 21.5888 11.274 22.9992 6.18756 26.948ZM23.6799 28.4344L22.5001 27.8578V24.1875H24.8063L23.6799 28.4344ZM27.0001 20.8125H22.5001V12.9375H27.0001C28.0443 12.9375 29.0459 13.3524 29.7843 14.0908C30.5227 14.8292 30.9376 15.8307 30.9376 16.875C30.9376 17.9193 30.5227 18.9208 29.7843 19.6592C29.0459 20.3977 28.0443 20.8125 27.0001 20.8125Z"
        fill="#004F57"
      />
    </svg>
  );
}

export default BullhornIcon;
