import React from "react";

function LargeAudienceIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 9.5625C9.2684 9.5625 6.75 12.0809 6.75 15.1875C6.75 18.2941 9.2684 20.8125 12.375 20.8125C15.4816 20.8125 18 18.2941 18 15.1875C18 12.0809 15.4816 9.5625 12.375 9.5625ZM3.375 15.1875C3.375 10.2169 7.40444 6.1875 12.375 6.1875C17.3456 6.1875 21.375 10.2169 21.375 15.1875C21.375 20.1581 17.3456 24.1875 12.375 24.1875C7.40444 24.1875 3.375 20.1581 3.375 15.1875Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8306 6.18751L23.8358 6.1875C26.2227 6.1875 28.5119 7.13571 30.1997 8.82354C31.8876 10.5114 32.8358 12.8006 32.8358 15.1875C32.8358 17.5744 31.8876 19.8636 30.1997 21.5515C28.5119 23.2393 26.2227 24.1875 23.8358 24.1875C22.9038 24.1875 22.1483 23.432 22.1483 22.5C22.1483 21.568 22.9038 20.8125 23.8358 20.8125C25.3276 20.8125 26.7584 20.2199 27.8133 19.165C28.8681 18.1101 29.4608 16.6793 29.4608 15.1875C29.4608 13.6957 28.8681 12.2649 27.8133 11.21C26.759 10.1557 25.3292 9.56317 23.8383 9.5625C23.3169 9.56431 22.7979 9.63436 22.2947 9.77085C21.3952 10.0148 20.4683 9.48339 20.2243 8.58391C19.9803 7.68442 20.5118 6.75748 21.4112 6.51353C22.2 6.2996 23.0134 6.19 23.8306 6.18751Z"
        fill="#004F57"
      />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3751 24.187C10.6601 24.187 8.97017 24.5998 7.44821 25.3904C5.92625 26.1811 4.61692 27.3264 3.63082 28.7296C3.09497 29.4922 2.04243 29.6759 1.2799 29.1401C0.517368 28.6042 0.333609 27.5517 0.86946 26.7891C2.16696 24.9428 3.88976 23.4358 5.89234 22.3954C7.89492 21.3551 10.1185 20.812 12.3751 20.812C14.6318 20.812 16.8554 21.3551 18.8579 22.3954C20.8605 23.4358 22.5833 24.9428 23.8808 26.7891C24.4167 27.5517 24.2329 28.6042 23.4704 29.1401C22.7079 29.6759 21.6553 29.4922 21.1195 28.7296C20.1334 27.3264 18.824 26.1811 17.3021 25.3904C15.7801 24.5998 14.0902 24.187 12.3751 24.187Z"
        fill="#004F57"
      />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7637 25.3891C27.2418 24.5986 25.5519 24.1864 23.837 24.1875C22.905 24.1881 22.149 23.433 22.1484 22.501C22.1479 21.5691 22.9029 20.8131 23.8349 20.8125C26.0921 20.8111 28.3164 21.3536 30.3194 22.3941C32.3225 23.4346 34.0454 24.9424 35.3421 26.7899C35.8776 27.5527 35.6933 28.6051 34.9304 29.1406C34.1676 29.676 33.1152 29.4917 32.5797 28.7289C31.5945 27.3252 30.2855 26.1796 28.7637 25.3891Z"
        fill="#004F57"
      />
    </svg>
  )
}

export default LargeAudienceIcon;
