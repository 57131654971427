import { createSelector } from "reselect";
import sortBy from "lodash/sortBy";


const getAllPeriods = state => state.periods.allPeriods;

export const getTopPeriods = createSelector(
  getAllPeriods,
  (allPeriods) => {
    const topPeriods = [];

    for (let i = 0; i < allPeriods.length; i++) {

      switch (allPeriods[i].url) {
        case "georgian-antiques-(1714-to-1837)":
          topPeriods.push({ ...allPeriods[i], sortValue: 0 });
          break;
        case "regency-(1811-1820)":
          topPeriods.push({ ...allPeriods[i], sortValue: 1 });
          break;
        case "victorian-(1837-1901)":
          topPeriods.push({ ...allPeriods[i], sortValue: 2 });
          break;
        case "arts-and-crafts-(1880-1910)":
          topPeriods.push({ ...allPeriods[i], sortValue: 3 });
          break;
        case "art-nouveau-(1890-1910)":
          topPeriods.push({ ...allPeriods[i], sortValue: 4 });
          break;
        case "edwardian-(1901-1910)":
          topPeriods.push({ ...allPeriods[i], sortValue: 5 });
          break;
        case "art-deco":
          topPeriods.push({ ...allPeriods[i], sortValue: 6 });
          break;
        case "retro-and-vintage-(1950s-70s)":
          topPeriods.push({ ...allPeriods[i], sortValue: 7 });
          break;
        default:
          break;
      }
    }

    return sortBy(topPeriods, "sortValue");
  }
);
