import React from "react";

function ChatsIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1875 7.3125V21.2168L9.00812 18.9375C9.30837 18.6948 9.68272 18.5625 10.0687 18.5625H23.0625V7.3125H6.1875ZM3.63626 4.76126C4.16371 4.23382 4.87908 3.9375 5.625 3.9375H23.625C24.3709 3.9375 25.0863 4.23382 25.6137 4.76126C26.1412 5.28871 26.4375 6.00408 26.4375 6.75V19.125C26.4375 19.8709 26.1412 20.5863 25.6137 21.1137C25.0863 21.6412 24.3709 21.9375 23.625 21.9375H10.6653L5.56063 26.0625C5.05492 26.4712 4.35943 26.5529 3.77273 26.2727C3.18603 25.9925 2.8125 25.4002 2.8125 24.75V6.75C2.8125 6.00408 3.10882 5.28871 3.63626 4.76126Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0625 12.375C23.0625 11.443 23.818 10.6875 24.75 10.6875H30.375C31.1209 10.6875 31.8363 10.9838 32.3637 11.5113C32.8912 12.0387 33.1875 12.7541 33.1875 13.5V31.5C33.1875 32.1502 32.814 32.7425 32.2273 33.0227C31.6406 33.3029 30.9451 33.2212 30.4394 32.8125L25.3347 28.6875H12.375C11.6291 28.6875 10.9137 28.3912 10.3863 27.8637C9.85882 27.3363 9.5625 26.6209 9.5625 25.875V20.25C9.5625 19.318 10.318 18.5625 11.25 18.5625C12.182 18.5625 12.9375 19.318 12.9375 20.25V25.3125H25.9312C26.3173 25.3125 26.6916 25.4448 26.9919 25.6875L29.8125 27.9668V14.0625H24.75C23.818 14.0625 23.0625 13.307 23.0625 12.375Z"
        fill="#004F57"
      />
    </svg>
  )
}

export default ChatsIcon;
