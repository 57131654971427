import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

// assets
import "../../assets/styles/shared/sidebarItems.scss";

function SidebarItems({
  title,
  items = [],
  firstRow = null,
  lastRow = null,
  linkTo,
  isSorted = false,
  selectedItem = null,
  overflownLinks = false,
  ...rest
}) {
  const location = useLocation();
  const isLocationAHomePage = location.pathname === "/";
  const homeCategoriesSidebar =
    title === "Antique Categories" && isLocationAHomePage;

  let sortedItems = sortBy(items, "title");
  if (isSorted) {
    sortedItems = items;
  } else if (linkTo === "period") {
    sortedItems = sortBy(items, "sortValue");
  }

  const generateLink = (itemUrl) => {
    switch (linkTo) {
      case "period":
        return `/period/${itemUrl}`;
      case "category":
        return `/${itemUrl}`;
      case "homeAndLiving":
        return `/home-and-living/${itemUrl}`;
      case "material":
        return `/knowledge/material/${itemUrl}`;
      case "origin":
        return `/knowledge/origin/${itemUrl}`;
      default:
        return `/${itemUrl}`;
    }
  };

  if (!sortedItems?.length) {
    return null;
  }

  return (
    <div className="home-sidebar" {...rest}>
      <h2 className="home-sidebar--title-container">{title}</h2>

      <div
        className={`home-sidebar--links ${
          overflownLinks ? "overflow-auto" : ""
        }`}
      >
        {firstRow}

        {sortedItems.map((item) => {
          const numberOfItems = item ? get(item, "itemsCount", 0) : 0;
          const link = generateLink(item.url);
          const linkTitle = isLocationAHomePage
            ? `Antique ${item.title}`
            : null;
          const itemTitle = homeCategoriesSidebar
            ? `Antique ${item.title}`
            : item.title;
          const isSelected = item.url.split("/")[1] === selectedItem;

          return (
            <Link
              to={link}
              title={linkTitle}
              key={item.id}
              data-testid="lant-side-bar-item-link"
              className="home-sidebar--row--link"
            >
              <div
                className="home-sidebar--row"
                data-testid={`lant-side-bar-${link}`}
              >
                <span
                  data-testid="lant-side-bar-item-label"
                  className={`home-sidebar--row-item ${
                    isSelected ? "selected-sub-category" : ""
                  }`}
                >
                  {itemTitle}
                </span>
                <div
                  data-testid="lant-side-bar-item-counter"
                  className="home-sidebar--row-items-count"
                >
                  {numberOfItems}
                </div>
              </div>
            </Link>
          );
        })}

        {lastRow}
      </div>
    </div>
  );
}

SidebarItems.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  firstRow: PropTypes.node,
  lastRow: PropTypes.node,
  linkTo: PropTypes.node,
  isSorted: PropTypes.bool,
  selectedItem: PropTypes.string,
  overflownLinks: PropTypes.bool,
};

export default React.memo(SidebarItems);
