import React from "react";

function SecureIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3125 8.4375V16.1297C7.3125 26.4366 15.7715 30.073 18 30.8497C20.2285 30.073 28.6875 26.4366 28.6875 16.1297V8.4375H7.3125ZM4.76126 5.88626C5.28871 5.35882 6.00408 5.0625 6.75 5.0625H29.25C29.9959 5.0625 30.7113 5.35882 31.2387 5.88626C31.7662 6.41371 32.0625 7.12908 32.0625 7.875V16.1297C32.0625 29.1019 21.0461 33.3938 18.904 34.1072C18.3192 34.3149 17.6807 34.3149 17.096 34.1072C14.9538 33.3938 3.9375 29.1019 3.9375 16.1297V7.875C3.9375 7.12908 4.23382 6.41371 4.76126 5.88626Z"
        fill="#004F57"
      />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4085 13.4602C26.0518 14.1345 26.0267 15.2027 25.3523 15.846L17.0976 23.721C16.4452 24.3434 15.4188 24.343 14.7669 23.72L10.6466 19.7825C9.97284 19.1386 9.94861 18.0704 10.5925 17.3966C11.2364 16.7228 12.3046 16.6986 12.9784 17.3425L15.9338 20.1668L23.0227 13.404C23.697 12.7607 24.7652 12.7858 25.4085 13.4602Z"
        fill="#004F57"
      />
    </svg>
  )
}

export default SecureIcon;
