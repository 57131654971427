import React from "react";
import PropTypes from "prop-types";
import ShowMoreText from "react-show-more-text";
import classNames from "classnames";

// assets
import "../../assets/styles/shared/show-more.scss";

const ShowMore = ({
  children,
  className,
  lines = 3,
  expanded = false,
  more = "See more",
  less = "See less",
}) => {
  return (
    <ShowMoreText
      more={more}
      less={less}
      lines={lines}
      expanded={expanded}
      truncatedEndingComponent="... "
      className={classNames("lant-show-more", className)}
    >
      {children}
    </ShowMoreText>
  );
};

ShowMore.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  lines: PropTypes.number,
  more: PropTypes.string,
  less: PropTypes.string,
};

export default ShowMore;
