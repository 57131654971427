import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

// components
import FavoriteItemButton from "./FavoriteItemButton";

// helpers
import { handleCurrencySymbol, formatPrice } from "../../helpers";

// constants
import { NO_IMAGE_URL } from "../../constants";

// styles
import "../../assets/styles/shared/itemBlock.scss";

const getItemImageURL = (itemImage) => {
  if (!isEmpty(itemImage) && itemImage.thumbnailUrl) {
    return itemImage.thumbnailUrl;
  }

  if (itemImage.url) {
    return itemImage.url;
  }

  return NO_IMAGE_URL;
};

const ItemBlock = React.forwardRef(
  (
    {
      item,
      xl = 6,
      lg = 6,
      sm = 8,
      md = 8,
      xs = 24,
      className,
      imageAsBackground,
      onClick,
    },
    ref
  ) => {
    const itemImageURL = getItemImageURL(get(item, "image", {}));
    const itemTitle = get(item, "title", "");
    const currencyToDisplay = handleCurrencySymbol(
      get(item, "currency.currency", "GBP")
    );
    const itemURL = `/items/listings/${item.url}`;

    return (
      <>
        <Col xl={xl} lg={lg} md={md} sm={sm} xs={0}>
          <div
            className={["home-item", className].filter(Boolean).join(" ")}
            ref={ref}
            onClick={() => onClick && onClick()}
            data-testid="lant-item-block"
          >
            <div className="home-item--image-holder">
              <div
                className="home-item--image-container"
                style={{ position: "relative" }}
              >
                <Link to={itemURL} data-testid="lant-item-block-link">
                  {imageAsBackground ? (
                    <div className="home-item--image">
                      <div
                        style={{
                          width: "172px",
                          height: "172px",
                          backgroundColor: "#f2f2f2",
                          backgroundImage: `url(${itemImageURL})`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      className="home-item--image"
                      src={itemImageURL}
                      alt={itemTitle}
                      title={itemTitle}
                      height={180}
                      width={180}
                      loading="lazy"
                      data-testid="lant-item-block-image"
                    />
                  )}
                </Link>

                <FavoriteItemButton
                  className="home-item--svg-heart-container"
                  item={item}
                />
              </div>
            </div>
            <div className="home-item--content-holder">
              <div>
                <p
                  className="home-item--owner"
                  data-testid="lant-item-block-dealer-business-name"
                >
                  {item.dealer.status !== "INACTIVE"
                    ? item.dealer.businessName
                    : ""}
                </p>

                <Link to={itemURL} data-testid="lant-item-block-title-link">
                  <p
                    className="home-item--title"
                    data-testid="lant-item-block-title"
                  >
                    {item.title}
                  </p>
                </Link>
              </div>

              {item.isSold ? (
                <div
                  className="home-item--item-price--sold"
                  data-testid="lant-item-block-status-sold"
                >
                  Sold
                </div>
              ) : item.isReserved ? (
                <div
                  className="home-item--item-price--sold"
                  data-testid="lant-item-block-status-reserved"
                >
                  Reserved
                </div>
              ) : (
                <div>
                  <p
                    className="home-item--price"
                    data-testid="lant-item-block-status-price"
                  >
                    {item && item.makeThisPricePoa
                      ? "POA"
                      : `${currencyToDisplay} ${formatPrice(item.price)}`}
                  </p>

                  <div className="flex justify-around">
                    {item &&
                      !item.makeThisPricePoa &&
                      item.prices &&
                      Object.values(item.prices).map((secondaryPrice) => {
                        const secondaryPriceValue = formatPrice(
                          Object.values(secondaryPrice)[0],
                          { maximumFractionDigits: 0 }
                        );
                        const secondaryPriceCurrency = handleCurrencySymbol(
                          Object.keys(secondaryPrice)[0]
                        );
                        const itemSecondaryPrice = `${secondaryPriceCurrency}${secondaryPriceValue}`;

                        return (
                          <div
                            key={Object.keys(secondaryPrice)}
                            className="home-item--item-price--secondary-price-value"
                            data-testid="lant-item-block-secondary-price"
                          >
                            {itemSecondaryPrice}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>

        <Col xl={0} lg={0} md={0} sm={0} xs={xs}>
          <div
            className="home-item--mobile-container"
            data-testid="lant-item-block-mobile"
          >
            <Row align="middle" className="mb-0">
              <Col span={12}>
                <div className="image-container">
                  <FavoriteItemButton
                    className="home-item--svg-heart-container home-item--svg-heart-container__mobile"
                    item={item}
                  />

                  <Link to={itemURL} data-testid="lant-item-block-link-mobile">
                    <img
                      className="image-container--image"
                      src={itemImageURL}
                      alt={itemTitle}
                      title={itemTitle}
                      height={180}
                      width={180}
                      loading="lazy"
                      data-testid="lant-item-block-image-mobile"
                    />
                  </Link>
                </div>
              </Col>

              <Col span={12}>
                <div className="content-container">
                  <Link
                    to={itemURL}
                    data-testid="lant-item-block-title-link-mobile"
                  >
                    <p
                      className="content-container--title"
                      data-testid="lant-item-block-title-mobile"
                    >
                      {item.title}
                    </p>
                  </Link>

                  <div className="content-container--price-holder">
                    {item.isSold ? (
                      <div
                        className="home-item--item-price--sold mobile"
                        data-testid="lant-item-block-status-sold-mobile"
                      >
                        Sold
                      </div>
                    ) : item.isReserved ? (
                      <div
                        className="home-item--item-price--sold mobile"
                        data-testid="lant-item-block-status-reserved-mobile"
                      >
                        Reserved
                      </div>
                    ) : (
                      <div>
                        <p
                          className="home-item--price-mobile"
                          data-testid="lant-item-block-status-price-mobile"
                        >
                          {item && item.makeThisPricePoa
                            ? "POA"
                            : `${currencyToDisplay} ${formatPrice(item.price)}`}
                        </p>

                        <div className="flex">
                          {item &&
                            !item.makeThisPricePoa &&
                            item.prices &&
                            Object.values(item.prices).map((price) => {
                              const secondaryPriceValue = Math.round(
                                Object.values(price)[0]
                              );
                              const secondaryPriceCurrency =
                                handleCurrencySymbol(Object.keys(price)[0]);
                              const itemSecondaryPrice = `${secondaryPriceCurrency}${secondaryPriceValue}`;

                              return (
                                <div
                                  key={Object.keys(price)}
                                  className="home-item--item-price--secondary-price-value"
                                  data-testid="lant-item-block-secondary-price-mobile"
                                >
                                  {itemSecondaryPrice}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className="home-item--owner-mobile"
                      data-testid="lant-item-block-dealer-business-name-mobile"
                    >
                      {item.dealer ? item.dealer.businessName || "" : ""}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    );
  }
);

ItemBlock.displayName = "ItemBlock";

ItemBlock.propTypes = {
  item: PropTypes.object.isRequired,
  md: PropTypes.number,
  xl: PropTypes.number,
  lg: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  className: PropTypes.string,
  imageAsBackground: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ItemBlock;
