import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// components
import SidebarItems from "../../shared/SidebarItems";
import SubscribeNewsletter from "../../shared/SubscribeNewsletter";
import ItemBlockSkeleton from "../../shared/ItemBlockSkeleton";
import ItemBlock from "../../shared/ItemBlock";

// redux
import { getTopPeriods } from "../../../redux/periods/periodsSelector";
import { getFavoriteItemsIdsRequest } from "../../../redux/profiles/profilesActions";

const ItemsSection = ({
  items,
  isAuthenticated,
  getFavoriteItemsIds,
  itemsLoading,
  categories,
  periods,
}) => {
  // to show proper Jewellery category both on staging and production
  const categoriesToRender = categories.map((category) => {
    if (category.title === "Jewellery") {
      return { ...category, url: "vintage-jewellery" };
    }

    return category;
  });

  useEffect(() => {
    if (isAuthenticated) {
      getFavoriteItemsIds();
    }
  }, []);

  return (
    <div className="page-container">
      <Row gutter={[30, 30]} className="home--body-container">
        <Col xs={0} xl={6}>
          <SidebarItems
            title="Antique Categories"
            items={categoriesToRender}
            linkTo="category"
            data-testid="home-sidebar-categories"
            firstRow={
              <div className="home-sidebar--row">
                <Link to="/antiques" className="home-sidebar--row--link">
                  All Categories
                </Link>
              </div>
            }
          />

          <SidebarItems
            title="Antique Period"
            items={periods}
            linkTo="period"
            data-testid="home-sidebar-periods"
            lastRow={
              <Link to="/period" className="home-sidebar--row">
                View All Periods
              </Link>
            }
          />

          <SubscribeNewsletter data-testid="home-sidebar-subscribe-for-new-letters" />
        </Col>

        <Col xl={18} sm={24} className="home--items-block-container">
          <section
            className="home--items"
            data-testid="home-items-block-container"
          >
            <h2 className="h2">Latest Items from our Antiques Store</h2>

            <Row gutter={[30, 30]} className="home--items-container">
              {itemsLoading ? (
                <ItemBlockSkeleton amount={16} />
              ) : (
                items.map((item) => <ItemBlock key={item.id} item={item} />)
              )}
            </Row>

            <Link
              to="/antiques"
              className="home--button-to-antiques"
              data-testid="home-button-to-antiques"
            >
              <Button size="middle">
                Click to see more recently added antiques...
              </Button>
            </Link>
          </section>
        </Col>
      </Row>
    </div>
  );
};

ItemsSection.propTypes = {
  items: PropTypes.array.isRequired,
  itemsLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getFavoriteItemsIds: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    items: state.home.items,
    itemsLoading: state.home.itemsLoading,
    categories: state.home.allCategories,
    periods: getTopPeriods(state),
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    getFavoriteItemsIds: getFavoriteItemsIdsRequest,
  }
)(ItemsSection);
