import * as React from "react";
import PropTypes from "prop-types";


function GreenHeartIcon({ fill = "none", stroke = "#004F57", }) {
  return (
    <svg width={23} height={21}>
      <g fill={fill} fillRule="evenodd">
        <path d="M0-2h24v24H0z" />
        <path
          d="M20.291 2.612a5.5 5.5 0 00-7.78 0l-1.06 1.06-1.06-1.06a5.501 5.501 0 00-7.78 7.78l1.06 1.06 7.78 7.78 7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"
          stroke={stroke}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

GreenHeartIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

export default GreenHeartIcon;
