import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


function DataLayer({ pageTitle }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "test") {
      return;
    }

    const pageTitleFromDom = document.title;

    const spaTagManagerScriptHandling = document.createElement("script");
    spaTagManagerScriptHandling.id = "gtmScript";
    spaTagManagerScriptHandling.innerHTML = `
    window.dataLayer.push({
      event: 'GAVPV',
      pageName: "${pageTitle || pageTitleFromDom}",
      pageUrl: "${pathname}",
    });`;

    document.head.appendChild(spaTagManagerScriptHandling);
  }, [pathname]);


  return (<React.Fragment/>);
}

DataLayer.propTypes = {
  pageTitle: PropTypes.string,
};

export default DataLayer;
