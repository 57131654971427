import React from "react";

function DiamondIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85503 4.51377C9.17546 4.14756 9.63839 3.9375 10.125 3.9375H25.875C26.3616 3.9375 26.8245 4.14756 27.145 4.51377L35.02 13.5138C35.5903 14.1656 35.5746 15.1433 34.9837 15.7764L19.2337 32.6514C18.9145 32.9934 18.4677 33.1875 18 33.1875C17.5323 33.1875 17.0855 32.9934 16.7663 32.6514L1.01635 15.7764C0.425409 15.1433 0.409716 14.1656 0.980028 13.5138L8.85503 4.51377ZM10.8907 7.3125L4.52424 14.5885L18 29.0268L31.4758 14.5885L25.1093 7.3125H10.8907Z"
        fill="#004F57" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9999 3.9375C18.5246 3.9375 19.0194 4.18155 19.3388 4.59783L26.2435 13.5978C26.6088 14.0741 26.6937 14.7085 26.4664 15.264L19.5617 32.139C19.3023 32.7732 18.6851 33.1875 17.9999 33.1875C17.3147 33.1875 16.6976 32.7732 16.4381 32.139L9.53341 15.264C9.30611 14.7085 9.391 14.0741 9.75636 13.5978L16.661 4.59783C16.9804 4.18155 17.4752 3.9375 17.9999 3.9375ZM17.9999 8.39734L13.0241 14.8831L17.9999 27.0439L22.9757 14.8831L17.9999 8.39734Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5625 14.625C0.5625 13.693 1.31802 12.9375 2.25 12.9375H33.75C34.682 12.9375 35.4375 13.693 35.4375 14.625C35.4375 15.557 34.682 16.3125 33.75 16.3125H2.25C1.31802 16.3125 0.5625 15.557 0.5625 14.625Z"
        fill="#004F57"
      />
    </svg>
  )
}

export default DiamondIcon;
