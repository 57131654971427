import * as React from "react";

function ReliableIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12.9375H11.25C12.182 12.9375 12.9375 13.693 12.9375 14.625V29.25C12.9375 30.182 12.182 30.9375 11.25 30.9375H4.5C3.75408 30.9375 3.03871 30.6412 2.51126 30.1137C1.98381 29.5863 1.6875 28.8709 1.6875 28.125V15.75C1.6875 15.0041 1.98382 14.2887 2.51126 13.7613C3.03871 13.2338 3.75408 12.9375 4.5 12.9375ZM5.0625 16.3125H9.5625V27.5625H5.0625V16.3125Z"
        fill="#004F57"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3655 2.62033C15.6514 2.04863 16.2357 1.6875 16.8749 1.6875C18.5159 1.6875 20.0897 2.3394 21.2501 3.49978C22.4105 4.66016 23.0624 6.23397 23.0624 7.875V9.5625H30.0777C30.6367 9.56145 31.1895 9.67987 31.6991 9.90985C32.2093 10.1401 32.6644 10.477 33.0337 10.8977C33.403 11.3184 33.678 11.8133 33.8402 12.3491C34.0017 12.8824 34.0477 13.444 33.9752 13.9964L32.2882 27.4922C32.1687 28.4406 31.7083 29.3131 30.9929 29.9471C30.2774 30.581 29.3558 30.933 28.3999 30.9375L28.3921 30.9375H11.2499C10.3179 30.9375 9.56238 30.182 9.56238 29.25C9.56238 28.318 10.3179 27.5625 11.2499 27.5625H28.3867C28.5224 27.5613 28.653 27.511 28.7546 27.421C28.8568 27.3305 28.9225 27.206 28.9396 27.0706C28.9396 27.0705 28.9397 27.0704 28.9397 27.0703L30.627 13.5719L30.6287 13.5584C30.6391 13.4808 30.6327 13.4019 30.61 13.327C30.5873 13.2521 30.5489 13.1829 30.4973 13.1241C30.4456 13.0653 30.382 13.0182 30.3107 12.986C30.2394 12.9539 30.162 12.9373 30.0837 12.9375L30.0796 12.9375H21.3749C20.4429 12.9375 19.6874 12.182 19.6874 11.25V7.875C19.6874 7.12908 19.3911 6.41371 18.8636 5.88626C18.5696 5.59223 18.2172 5.37003 17.8336 5.23094L12.7592 15.3797C12.3424 16.2133 11.3288 16.5511 10.4952 16.1343C9.66162 15.7176 9.32374 14.7039 9.74054 13.8703L15.3655 2.62033Z"
        fill="#004F57"
      />
    </svg>
  );
}

export default ReliableIcon;
