import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function MostPopularCategory({ title, url, image }) {
  const imageUrl = `${process.env.REACT_APP_IMAGES_URL}storefront_images/most-popular/${image}`;

  return (
    <div
      className="home--category-block-container--block"
      data-testid="home-category-block-container-block"
    >
      <Link
        to={`/${url}`}
        title={title}
        data-testid="home-category-block-container-block-link"
      >
        <img
          className="home--category-image"
          src={imageUrl}
          alt={title}
          title={title}
          width={213}
          height={188}
        />
      </Link>
      <p className="home--category-block-container--block--title">{title}</p>
    </div>
  );
}

MostPopularCategory.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default MostPopularCategory;
