export const MAIN_CATEGORIES = [
  {
    title: "Antique Furniture",
    url: "antique-furniture",
    image: "antique-furniture.webp",
  },
  {
    title: "Antique Jewellery",
    url: "vintage-jewellery",
    image: "antique-jewellery.webp",
  },
  {
    title: "Mirrors",
    url: "antique-mirrors",
    image: "antique-mirrors.webp",
  },
  {
    title: "Silver",
    url: "antique-silver",
    image: "antique-silver.webp",
  },
  {
    title: "Antique Lighting",
    url: "antique-lighting",
    image: "antique-lighting.webp",
  },
  {
    title: "Chairs",
    url: "antique-chairs",
    image: "most-popular-chairs.webp",
  },
  {
    title: "Glassware",
    url: "antique-glassware",
    image: "antique-glassware.webp",
  },
  {
    title: "Sculptures",
    url: "antique-sculptures",
    image: "antique-sculptures.webp",
  },
  {
    title: "Clocks",
    url: "antique-clocks",
    image: "antique-clocks.webp",
  },
  {
    title: "Ceramics",
    url: "antique-ceramics",
    image: "antique-ceramics.webp",
  },
  {
    title: "Art",
    url: "antique-art",
    image: "antique-art.webp",
  },
  {
    title: "All Antiques",
    url: "antiques",
    image: "all-antique-most-popular.webp",
  },
];
